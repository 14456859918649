import { RealtyCloudApi } from '../../api';
import { ApiAdminTariffMethods, apiAdminTariffMethods } from './tariff';

export interface ApiAdminMethods {
  Users<T = any[]>(params?: AdminUsersParams): Promise<T>;
  Orders<T = AdminOrderInfo[]>(params?: AdminOrdersParams): Promise<T>;
  CreateCoupon<T>(data: CreateCouponData): Promise<T>;
  GetSourceList<T = SourceItem[]>(): Promise<T>;
  GetCoupons<T>(params?: AdminCouponsParams): Promise<T>;
  GetUserInfo<T>(userID: string): Promise<T>;
  GetCampaigns<T>(): Promise<T>;
  ManualUpdate<T>(data: ManualUpdateData): Promise<T>;
  ManualAttachOrder<T>(params: ManualAttachOrderParams): Promise<T>;
  ManualAttachOrderItem<T>(params: ManualAttachOrderItemParams): Promise<T>;
  SearchUsers<T = any[]>(params?: AdminOrdersParams): Promise<T>;
  Stats<T = any>(params: StatsDateParams): Promise<T>;
  TopDonators<T = StatsDonator[]>(params: StatsDateParams): Promise<T>;
  TopDonatorsResponsible<T = any>(params: StatsDateParams): Promise<T>;
  OrderItemsList<T = AdminOrderItem2[]>(params: AdminOrdersParams): Promise<T>;
  StatusesList<T = { statuses: string[] }>(): Promise<T>;
  UpdateOrderItemKadnetStatus<T = any>(body: UpdateKadnetStatusRequestBody): Promise<T>;
  UpdateSpvOrderItemStatus<T = any>(orderItemId: OrderItemID): Promise<T>;
  UpdateOrderItemStatus<T = any>(orderItemId: OrderItemID, status: string): Promise<T>;
  DownloadSpvOrderItem<T = any>(orderItemId: OrderItemID): Promise<T>;
  SendEmailWithAttachment<T = any>(orderItemID: OrderItemID): Promise<T>;
  Emails<T = any>(params: AdminEmailsParams): Promise<T>;
  PartnerStats<T = AdminPartnerStatsInfoResponse>(params: StatsDateParams & PaginationParams): Promise<T>;
  StatsGraphic<T = any>(params: any): Promise<T>;

  /** Добавление ответственного к пользователю */
  ResponsibleUser<T = any>(body: Api.Admin.RequestBody.ResponsibleUser): Promise<T>;

  /** Получение ответственного за пользователя */
  GetResponsibleUser<T = any>(controlledPersonId: string): Promise<T>;

  /** Удаление ответственнного */
  RemoveResponsible<T = RemoveResponsibleResponse>(data: Api.Admin.RequestBody.RemoveResponsible): Promise<T>;

  /** Возвращение денег за заказ */
  Refunds<T = Api.Admin.Response.Refunds>(orderItemID: OrderItemID): Promise<T>;

  /** Снятие средств с баланса партнёра */
  PartnerWithdrawMoney<T = any>(data: Api.Admin.RequestBody.PartnerWithdrawMoney): Promise<T>;

  /** Снятие средств с баланса аккаунта */
  AccountWithdrawMoney<T = any>(data: Api.Admin.RequestBody.AccountWithdrawMoney): Promise<T>;

  /** Восстановление удаленного аккаунта */
  RecoverAccount<T = RecoverAccountResponse>(personId: RecoverAccountData): Promise<T>;

  /** Получение списка счетов пользователя */
  GetBills<T = any>(data: AdminGetBillsParams): Promise<T>;

  DownloadOrder<T = any>(orderItemID: string, fileType: string): Promise<T>;

  Metriks<T = any>(params?: AdminMetriksParams & PaginationParams): Promise<T>;

  SendLinkEnterToEmail<T = any>(personId: string, kadnum: string): Promise<T>;

  tariff: ApiAdminTariffMethods;
}

export function adminMethods(this: RealtyCloudApi): ApiAdminMethods {
  return {
    Users: (params?: AdminUsersParams) => {
      return this.request({ url: '/admin/users', params });
    },

    Orders: (params?: AdminOrdersParams) => {
      return this.request({ url: '/admin/orders', params });
    },

    CreateCoupon: (data: CreateCouponData) => {
      return this.request({
        url: '/admin/coupon',
        method: 'POST',
        data,
      });
    },

    GetSourceList: () => {
      return this.request({ url: '/admin/source' });
    },

    GetCoupons: (params?: AdminCouponsParams) => {
      return this.request({ url: '/admin/coupon', params });
    },

    GetCampaigns: () => {
      return this.request({ url: '/admin/campaign' });
    },

    GetUserInfo: (userID: string) => {
      return this.request({ url: `/person/${userID}` });
    },

    ManualUpdate: (data: ManualUpdateData) => {
      return this.request({ url: '/admin/manual_update', method: 'PUT', data });
    },

    ManualAttachOrder: (data: ManualAttachOrderParams) => {
      return this.request({
        url: '/admin/orders/attach_order',
        method: 'POST',
        data,
      });
    },

    ManualAttachOrderItem: (data: ManualAttachOrderItemParams) => {
      return this.request({
        url: '/admin/orders/attach_product',
        method: 'POST',
        data,
      });
    },

    SearchUsers: (params: AdminOrdersParams) => {
      return this.request({ url: '/admin/users', params });
    },

    Stats: ({ from, to }: StatsDateParams) => {
      const params = {
        'date-from': from,
        'date-to': to,
      };

      return this.request({ url: '/admin/stats', params });
    },

    TopDonators: ({ from, to }: StatsDateParams) => {
      const params = {
        'date-from': from,
        'date-to': to,
      };
      return this.request({ url: '/admin/top_donators', params });
    },

    TopDonatorsResponsible: ({ from, to }: StatsDateParams) => {
      const params = {
        'date-from': from,
        'date-to': to,
      };
      return this.request({ url: '/admin/top_donators_responsible', params });
    },

    OrderItemsList: (params: AdminOrdersParams) => {
      return this.request({
        url: '/admin/order_item/list',
        params,
      });
    },

    StatusesList: () => {
      return this.request({
        url: '/admin/order_item/all_statuses',
      });
    },

    UpdateOrderItemKadnetStatus: (data) => {
      return this.request({
        url: '/admin/order_item/update_order_item_kadnet_status',
        method: 'PUT',
        data,
      });
    },

    UpdateSpvOrderItemStatus: (orderItemId: OrderItemID) => {
      return this.request({
        url: '/admin/order_item/update_order_item_spv_status',
        method: 'PUT',
        data: { order_item_id: orderItemId },
      });
    },

    UpdateOrderItemStatus: (orderItemId: OrderItemID, status: string) => {
      return this.request({
        url: '/admin/order_item/update_order_item_status',
        method: 'PUT',
        data: { order_item_id: orderItemId, status },
      });
    },

    DownloadSpvOrderItem: (orderItemId: OrderItemID) => {
      return this.request({
        url: '/admin/order_item/download_order_item_spv',
        method: 'PUT',
        data: { order_item_id: orderItemId },
      });
    },

    SendEmailWithAttachment: (orderItemID: OrderItemID) => {
      return this.request({
        url: '/admin/order_item/send_email_with_attachment',
        params: { orderItemID },
      });
    },

    Emails: (params: AdminEmailsParams) => {
      return this.request({
        url: '/admin/email',
        params,
      });
    },

    PartnerStats: ({ from, to, ...pagination }: StatsDateParams & PaginationParams) => {
      const params = {
        'date-from': from,
        'date-to': to,
        ...pagination,
      };
      return this.request({
        url: '/admin/partner_account_sum',
        params,
      });
    },

    PartnerWithdrawMoney: (data: Api.Admin.RequestBody.PartnerWithdrawMoney) => {
      return this.request({
        url: '/admin/partner/withdraw_money',
        method: 'POST',
        data,
      });
    },

    AccountWithdrawMoney: (data: Api.Admin.RequestBody.AccountWithdrawMoney) => {
      return this.request({
        url: '/admin/users/subtract_account_balance',
        method: 'PUT',
        data,
      });
    },

    Refunds: (orderItemId: string) => {
      return this.request({
        url: '/return_product',
        method: 'POST',
        data: { order_item_id: orderItemId },
      });
    },

    StatsGraphic: (params) => {
      return this.request({
        url: '/admin/stats_graphic',
        method: 'GET',
        params,
      });
    },

    ResponsibleUser: (data: Api.Admin.RequestBody.ResponsibleUser) => {
      return this.request({
        url: '/admin/responsible_user/add',
        method: 'POST',
        data,
      });
    },

    RecoverAccount: (personId: RecoverAccountData) => {
      return this.request({
        url: '/admin/users/reset_removed_person',
        method: 'POST',
        data: personId,
      });
    },

    GetResponsibleUser: (controlledPersonId: string) => {
      return this.request({ url: `/admin/users?controlledPersonID=${controlledPersonId}` });
    },

    RemoveResponsible: (data: Api.Admin.RequestBody.RemoveResponsible) => {
      return this.request({
        url: '/admin/responsible_user/remove',
        method: 'DELETE',
        data,
      });
    },

    GetBills: (params: AdminGetBillsParams) => {
      return this.request({
        url: '/invoice/list',
        params,
      });
    },

    DownloadOrder: (orderItemID: string, fileType: string) => {
      return this.request({
        url: `/download?orderID=${orderItemID}&fileType=${fileType}`,
      });
    },

    Metriks: (params: AdminMetriksParams) => {
      return this.request({
        url: '/admin/metrics',
        params,
      });
    },

    SendLinkEnterToEmail: (personId: string, kadnum: string) => {
      return this.request({
        url: '/admin/users/request_auth',
        method: 'POST',
        data: {
          person_id: personId,
          kadnum,
        },
      });
    },

    tariff: apiAdminTariffMethods.call(this),
  };
}
