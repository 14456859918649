































import Vue from 'vue';

export default Vue.extend({
  props: {
    selectable: Boolean,
    value: String,
    model: [Array, Object],
  },

  computed: {
    selected(): boolean {
      if (this.model) {
        return this.model.selected.some((id: string) => this.value === id);
      }

      return false;
    },
  },
});
