export default function initForm<T = any>(initCallback: (element: HTMLDivElement, options: T) => void) {
  const scriptElement = document.querySelector('script[src*="realtycloud.ru/forms"]');
  let formContainer: HTMLDivElement = document.querySelector('[data-realtycloud-form]');

  if (!formContainer) {
    if (scriptElement) {
      const parent = scriptElement.parentNode;

      formContainer = document.createElement('div');
      parent.insertBefore(formContainer, scriptElement);
    }
  }

  const options = (window as any).__RC_OPTIONS__ || {};

  initCallback(formContainer, options);
}
