


























import Vue from 'vue';
import slice from 'lodash/slice';

function randomName() {
  return Math.random()
    .toString(36)
    .substring(7);
}

export default Vue.extend({
  name: 'AppCheckbox',

  model: {
    prop: 'model',
    event: 'change',
  },

  props: {
    model: [ String, Boolean, Number, Object, Array ],
    value: [ String, Boolean, Number, Object ],
    size: String,
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    trueValue: {
      type: [ Boolean, String, Number ],
      default: true,
    },
    falseValue: {
      type: [ Boolean, String, Number ],
      default: false,
    },
  },

  data() {
    return {
      id: randomName(),
    };
  },

  computed: {
    isModelArray(): boolean {
      return Array.isArray(this.model);
    },

    isChecked(): boolean {
      if (this.isModelArray) {
        return this.model.find((v: any) => v === this.value) != null;
      }

      return this.model === this.trueValue;
    },
  },

  methods: {
    handleArrayCheckbox() {
      const model = slice(this.model);

      if (this.isChecked) {
        const index = model.indexOf(this.value);

        if (index !== -1) {
          model.splice(index, 1);
        }
      } else {
        model.push(this.value);
      }

      this.$emit('change', model);
    },

    onChange() {
      if (this.disabled) return;

      if (this.isModelArray) {
        this.handleArrayCheckbox();
      } else {
        this.$emit('change', this.isChecked ? this.falseValue : this.trueValue);
      }
    },
  },
});
