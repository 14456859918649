

















































import Vue from 'vue';
import Component from 'vue-class-component';
import startsWith from 'lodash/startsWith';

const DEFAULT_AVERAGE_TIME_FOR_PRODUCT: SimpleObject<number> = {
  EgrnRightListFast: 30,
  EgrnRightList: 24,
  EgrnObjectFast: 30,
  EgrnObject: 24,
  RiskAssessment: 48,
  RiskAssessmentFast: 50,
  RiskAssessmentAdvanced: 48,
};

@Component({
  props: {
    product: Object,
    isPurchased: Boolean,
  },
})
export default class PurchasedProductItemcomponent extends Vue {
  // props
  product!: Product;

  get isRiskProduct(): boolean {
    return startsWith(this.product.product_name, 'RiskAssessment');
  }

  get isFastProduct(): boolean {
    return /fast/i.test(this.product.product_name);
  }

  get time(): number {
    return DEFAULT_AVERAGE_TIME_FOR_PRODUCT[this.product.product_name];
  }
}
