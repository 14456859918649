var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-form",class:_vm.$style.app},[_c('div',{class:[
      _vm.$style.form,
      ( _obj = {}, _obj[_vm.$style.formDisabledImg] = _vm.state.options.disableImage, _obj )
    ]},[_c('div',{class:_vm.$style.formWrap},[_c('div',{class:_vm.$style.formBody},[_c('h1',{class:_vm.$style.appTitle},[_vm._v("\n          "+_vm._s(_vm.state.options.title)+"\n        ")]),_c('h2',{class:_vm.$style.appSubtitle},[_vm._v("\n          "+_vm._s(_vm.state.options.subtitle)+"\n        ")]),_c('app-form',{attrs:{"model":_vm.state.form,"rules":_vm.validationRules},on:{"submit":_vm.onSubmit}},[_c('app-form-group',{attrs:{"model-name":"query","label":"Введите адрес или кадастровый номер"}},[_c('app-dadata',{attrs:{"placeholder":"Например, Москва, мира 45, кв 5 или 77:88:89990:00","size":"lg"},model:{value:(_vm.state.form.query),callback:function ($$v) {_vm.$set(_vm.state.form, "query", $$v)},expression:"state.form.query"}})],1),_c('app-form-group',{attrs:{"model-name":"email"}},[_c('app-input',{attrs:{"type":"email","placeholder":"Email для получения документа","size":"lg"},model:{value:(_vm.state.form.email),callback:function ($$v) {_vm.$set(_vm.state.form, "email", $$v)},expression:"state.form.email"}})],1),_c('app-button',{attrs:{"type":"submit","size":"lg","loading":_vm.loading}},[_vm._v("\n            "+_vm._s(_vm.state.options.buttonText)+"\n          ")])],1)],1),_c('div',{class:_vm.$style.formImg,style:({
          'background-image': ("url(" + (_vm.state.options.imageUrl ? _vm.state.options.imageUrl : require('@/egrn-order/assets/rosreestr-bg.png')) + ")")
        })})])]),_c('app-dialog-root')],1)}
var staticRenderFns = []

export { render, staticRenderFns }