import { RealtyCloudApi } from '../api';
import { AxiosRequestConfig } from 'axios';

interface ProductsListParams {
  partnerID?: UUID;
  campaign?: string;
  kadastrNumber?: string;
  personRoleID?: number;
}

export interface ApiProductMethods {
  List<T = Product[]>(campaign?: string, params?: ProductsListParams): Promise<T>;
  ActivatePromoCode<T = ActivateCouponResponse>(code: string): Promise<T>;
}

export function productMethods(this: RealtyCloudApi): ApiProductMethods {
  return {
    List: (campaign?: string, p?: ProductsListParams) => {
      let params: AxiosRequestConfig['params'] = { };

      if (campaign) {
        params.campaign = campaign;
      }

      if (p) {
        params = { ...params, ...p };
      }

      return this.request({ url: '/products', params });
    },

    ActivatePromoCode: (code: string) => {
      return this.request({
        url: '/coupon',
        method: 'POST',
        data: { code },
      });
    },
  };
}
