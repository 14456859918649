import Vue from 'vue';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUIDefault, { Item } from 'photoswipe/dist/photoswipe-ui-default';
import PhotoSwipeContent from './PhotoSwipeContent.vue';

let vueInstance: Vue;
let pswpInstance: PhotoSwipe<any>;

export function destroyPhotoSwipe() {
  if (vueInstance) {
    vueInstance.$destroy();
    document.body.removeChild(vueInstance.$el);
    vueInstance = null;
  }

  if (pswpInstance) {
    pswpInstance.destroy();
    pswpInstance = null;
  }
}

export function initPhotoSwipe(items: Item[], dynamicSize?: boolean, options?: any) {
  const el = document.createElement('div');
  document.body.appendChild(el);

  vueInstance = new Vue({
    el,
    render: (h) => h(PhotoSwipeContent),
  });

  pswpInstance = new PhotoSwipe(vueInstance.$el as HTMLElement, PhotoSwipeUIDefault, items, {
    bgOpacity: 0.6,
    closeOnScroll: false,
    history: false,
    shareEl: false,
    index: options && options.index ? options.index : 0,
  });

  if (dynamicSize) {
    pswpInstance.listen('gettingData', function(index, item) {
      if (item.w < 1 || item.h < 1) {
        // unknown size
        let img = new Image();
        img.onload = function() {
          // will get size after load
          item.w = img.naturalWidth; // set image width
          item.h = img.naturalHeight; // set image height
          pswpInstance.invalidateCurrItems(); // reinit Items
          pswpInstance.updateSize(true); // reinit Items
          img.onload = null;
          img = null;
        };
        img.src = item.src; // let's download image
      }
    });
  }

  pswpInstance.listen('destroy', function() {
    pswpInstance = null;
    destroyPhotoSwipe();
  });

  pswpInstance.init();

  return pswpInstance;
}
