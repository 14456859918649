export type EventCallback = (...args: any[]) => void;

interface EmitterEvents {
  [eventName: string]: EventCallback[];
}

export default class EventEmitter {
  private _events: EmitterEvents = {};

  public on(event: string, ...handlers: EventCallback[]): void {
    if (!handlers.length) {
      return;
    }

    if (!this._events[event]) {
      this._events[event] = [];
    }

    this._events[event].push(...handlers);
  }

  public off(event: string, ...handlers: EventCallback[]): void {
    const eventCallbacks = this._events[event];
    if (!eventCallbacks) {
      return;
    }

    for (const callback of handlers) {
      for (let index = 0, l = eventCallbacks.length; index < l; index++) {
        const eventCallback = eventCallbacks[index];

        if (eventCallback === callback) {
          this._events[event].splice(index, 1);
        }
      }
    }
  }

  public once(event: string, handler: EventCallback): void {
    const once = (...args: any[]) => {
      this.off(event, once);
      handler(...args);
    };
    this.on(event, once);
  }

  public emit(event: string, ...args: any[]): void {
    if (!this._events[event]) {
      return;
    }

    for (const handler of this._events[event]) {
      handler(...args);
    }
  }
}
