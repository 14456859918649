import { RealtyCloudApi } from 'shared/api/api';

export interface ApiTransactionMethods {
  GetHistory<T = TransactionsHistoryResponse>(params?: SimpleObject<any>): Promise<T>;
}

export function apiTransactionMethods(this: RealtyCloudApi): ApiTransactionMethods {
  return {
    GetHistory: <T = TransactionsHistoryResponse>(params?: SimpleObject<any>): Promise<T> => {
      return this.request({
        url: '/transaction',
        params,
      });
    },
  };
}
