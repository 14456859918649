


























import Vue from 'vue';

export default Vue.extend({
  name: 'AppInput',

  props: {
    type: {
      type: String,
      default: 'text',
    },
    iconPosition: {
      type: String,
      default: 'right',
    },
    value: [ String, Number ],
    size: String,
    placeholder: String,
    tabindex: String,
    error: Boolean,
    inputClass: String,
    disabled: Boolean,
    min: [ Number, String ],
    max: [ Number, String ],
  },

  computed: {
    nativeInputValue(): string | number {
      return this.value == null ? '' : this.value;
    },
  },

  methods: {
    getEvents() {
      return {
        ...this.$listeners,
        input: (event: KeyboardEvent) => this.$emit('input', (event.target as any).value),
      };
    },
  },
});
