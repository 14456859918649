exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".form_appTitle_iNSm4{font-weight:700;font-size:46px;margin:0;padding-bottom:20px;line-height:1.1}.form_appSubtitle_2gzZS{font-weight:400;padding-bottom:30px;font-size:20px;line-height:1.5;margin:0}.form_form_34jQt{display:table;width:100%}.form_form_34jQt [class*=custom-select],.form_form_34jQt [class*=form-control]{height:50px;background-color:#f8f9fa}.form_form_34jQt [class*=custom-select]:focus,.form_form_34jQt [class*=form-control]:focus{background-color:#fff}.form_form_34jQt [class*=btn]{height:50px;padding-left:3rem;padding-right:3rem;font-weight:700}.form_formWrap_2KmsQ{display:table-row;width:100%}.form_formImg_1YLn3{display:none;width:30%;background-position:50%;background-repeat:no-repeat;background-size:contain}.form_link_1fWVH{color:#2281f9;text-decoration:underline;cursor:pointer}@media(min-width:991px){.form_formBody_3Qu_9{width:70%;padding-right:5rem}.form_formBody_3Qu_9,.form_formImg_1YLn3{display:table-cell;vertical-align:top}.form_formDisabledImg_1Ne6m .form_formBody_3Qu_9{padding-right:0}.form_formDisabledImg_1Ne6m .form_formImg_1YLn3{display:none}}@media(max-width:600px){.form_appTitle_iNSm4{font-size:30px;padding-bottom:1rem}.form_appSubtitle_2gzZS{font-size:18px;padding-bottom:1rem}.form_form_34jQt [class*=btn]{display:block;width:100%;padding-left:1rem;padding-right:1rem}}", ""]);

// exports
exports.locals = {
	"appTitle": "form_appTitle_iNSm4",
	"appSubtitle": "form_appSubtitle_2gzZS",
	"form": "form_form_34jQt",
	"formWrap": "form_formWrap_2KmsQ",
	"formImg": "form_formImg_1YLn3",
	"link": "form_link_1fWVH",
	"formBody": "form_formBody_3Qu_9",
	"formDisabledImg": "form_formDisabledImg_1Ne6m"
};