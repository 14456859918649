

























import Vue from 'vue';
import noop from 'lodash/noop';

import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';

import { AppState } from './app-types';

import AppSearchObjectsList from 'shared/components/SearchObjectsList.vue';
import AppOrder from './OrderStep.vue';
import AppCompleteOrder from './CompleteOrderStep.vue';
import { emitter, EVENTS } from './emitter';

@Component({
  components: { AppSearchObjectsList, AppOrder, AppCompleteOrder },

  props: {
    onSelect: {
      type: Function,
      default: noop,
    },
  },
})
export default class SearchObject extends Vue {
  @Inject() state: AppState;

  // step = 'success';
  searchResultCount: number = 0;
  savedSelectedProducts: ProductName[] = [];
  // object = null;

  created() {
    this.state.step = 'search';
    this.savedSelectedProducts = [...this.state.options.selectedProducts];
  }

  beforeDestroy() {
    this.state.step = 'form';
    this.state.orderItems = [];
    this.state.order = null;
    this.state.options.selectedProducts = [...this.savedSelectedProducts];
  }

  objectSelected(object: RealtyObject, searchResultCount: number) {
    // this.object = object;
    this.state.objectInfo = object;
    this.state.step = 'order';
    this.searchResultCount = searchResultCount;
    emitter.emit(EVENTS.OBJECT_SELECTED);
  }

  paymentSuccess() {
    this.state.step = 'success';
  }
}
