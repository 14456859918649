exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductWrapper_product_1qG_B{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:12px;background:#f8f9fa;border:1px solid #e9ecef;border-radius:.25rem}@media(max-width:650px){.ProductWrapper_product_1qG_B{-ms-flex-wrap:wrap;flex-wrap:wrap}}.ProductWrapper_productSelected_18YCc{background:#f1f4f9;border-color:#cad6e4}.ProductWrapper_checkbox_15jqz{-ms-flex-item-align:start;align-self:flex-start;margin-right:.75rem}.ProductWrapper_checkbox_15jqz [class=control]{margin-top:0}.ProductWrapper_productBody_3NQOc{margin-right:auto}@media(max-width:650px){.ProductWrapper_productBody_3NQOc{-ms-flex-preferred-size:100%;flex-basis:100%;margin-bottom:10px}}.ProductWrapper_productName_uxTha{font-size:16px;font-weight:600;color:#24292e}.ProductWrapper_productDesc_tBCnO{margin-top:3px;color:#6a737d;font-size:14px}.ProductWrapper_productDesc_tBCnO b,.ProductWrapper_productInfo_3B8TU{font-weight:600}.ProductWrapper_productInfo_3B8TU{-ms-flex-negative:0;flex-shrink:0;white-space:nowrap;color:#24292e}@media(min-width:650px){.ProductWrapper_productInfo_3B8TU{margin-left:16px}}.ProductWrapper_productActions_2mJjP{-ms-flex-negative:0;flex-shrink:0;white-space:nowrap;margin-left:16px;min-width:110px;text-align:right}", ""]);

// exports
exports.locals = {
	"product": "ProductWrapper_product_1qG_B",
	"productSelected": "ProductWrapper_productSelected_18YCc",
	"checkbox": "ProductWrapper_checkbox_15jqz",
	"productBody": "ProductWrapper_productBody_3NQOc",
	"productName": "ProductWrapper_productName_uxTha",
	"productDesc": "ProductWrapper_productDesc_tBCnO",
	"productInfo": "ProductWrapper_productInfo_3B8TU",
	"productActions": "ProductWrapper_productActions_2mJjP"
};