






























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import qs from 'query-string';
import startsWith from 'lodash/startsWith';
import includes from 'lodash/includes';

import AppProductWrap from './components/ProductWrap.vue';
import { AppState } from './app-types';
// import { emitter, EVENTS } from './emitter';

@Component({
  name: 'AppCompleteRegorderDialog',

  components: { AppProductWrap },
})
export default class CompleteOrderStep extends Vue {
  @Inject() state: AppState;

  get purchasedProducts(): Product[] {
    let purchasedProducts = this.state.orderItems.map(item => item.product_id);

    return purchasedProducts.map<Product>(productId => {
      return this.state.products.byName[productId];
    });
  }

  get recommendedProducts(): Product[] {
    if (!this.state.options.recommendedProducts) {
      return [];
    }

    const recommendedProducts = this.state.options.recommendedProducts.filter((productName) => {
      console.log(productName, this.purchasedProducts.some(p => startsWith(p.product_name, productName)));
      return !this.purchasedProducts.some(p => startsWith(p.product_name, productName));
    });

    const products = this.state.products.items.filter(p => {
      return includes(recommendedProducts, p.product_name);
    });

    return products;
  }

  onButtonClick(isPurchased: boolean) {
    if (!isPurchased) {
      this.state.step = 'order';

      const notPurchasedProductNames = this.state.options.selectedProducts.filter(name => {
        return !this.purchasedProducts.some(p => p.product_name === name);
      });
      const recommendedProductNames = this.recommendedProducts.map(p => p.product_name);

      this.state.options.selectedProducts = notPurchasedProductNames.concat(recommendedProductNames);
    }
  }

  redirect(redirectToPay: boolean, productName: ProductName) {
    if (!this.state.objectInfo || !this.state.order) {
      return;
    }

    const [ orderItem ] = this.state.order.order_items;
    const query: any = { id: orderItem.order_item_id };

    if (redirectToPay) {
      query.redirect = 'order';
      query.product = productName;
    }

    // emitter.emit('redirect');

    let objectKey = this.state.objectInfo.Number || this.state.objectInfo.Address;
    objectKey = objectKey.replace(/\//, '%2F');

    const url = `https://egrn.realtycloud.ru/object/${objectKey}?${qs.stringify(query)}`;

    location.assign(url);
  }
}
