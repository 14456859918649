// import includes from 'lodash/includes';
// import { isCadastralNumber } from './helpers';

// // Список регионов доступных для быстрого заказа через https://spv.kadastr.ru/
// const regions = [
//   '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '23', '24', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '62', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '77', '78', '79', '83', '86', '87', '89',
// ];

export default function checkCadastralRegions(cadastralNumber?: string): boolean {
  return true;
  /* if (isCadastralNumber(cadastralNumber)) {
    const cadastralRegion = cadastralNumber.slice(0, 2);

    return includes(regions, cadastralRegion);
  }

  return false; */
}
