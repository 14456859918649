import Vue from 'vue';
import buttonComponent from 'shared/components/ui/Button';
import inputComponent from 'shared/components/ui/Input';
import formComponent from 'shared/components/ui/Form';
import phoneNumberInputComponent from 'shared/components/ui/PhoneNumberInput';
import dadataComponent from 'shared/components/ui/Dadata';
import initDialogPlugin from 'shared/components/ui/Dialog/dialogPlugin';
import loaderComponent from 'shared/components/ui/Loader';
import checkboxComponent from 'shared/components/ui/Checkbox';
import installTippyPlugin from 'shared/directives/tippy';
import installPhotoSwipePlugin from 'shared/plugins/photoswipe';

Vue.use(formComponent);
Vue.use(installPhotoSwipePlugin);
Vue.use(inputComponent);
Vue.use(buttonComponent);
Vue.use(phoneNumberInputComponent);
Vue.use(dadataComponent);
Vue.use(initDialogPlugin);
Vue.use(loaderComponent);
Vue.use(checkboxComponent);
Vue.use(installTippyPlugin, {
  animateFill: false,
  theme: 'light',
  arrow: true,
  a11y: false,
});
