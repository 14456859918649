import { RealtyCloudApi } from 'shared/api/api';

export interface ApiResetsMethods {
  RosreestrSearch(): Promise<any>;
  BlockedIP(): Promise<any>;
  ManualUpdateAuto(params: StatsDateParams): Promise<any>;
  KadnetManualReOrder(params: StatsDateParams): Promise<any>;
}

export function resetsMethods(this: RealtyCloudApi): ApiResetsMethods {
  return {
    RosreestrSearch: () => {
      return this.request({
        url: '/reset/search',
        method: 'PUT',
      });
    },

    BlockedIP: () => {
      return this.request({
        url: '/reset/ip',
        method: 'PUT',
      });
    },

    ManualUpdateAuto: ({ from, to }: StatsDateParams) => {
      const data = {
        date_from: from,
        date_to: to,
      };

      return this.request({
        url: '/admin/manual_update_auto',
        method: 'POST',
        data,
      });
    },

    KadnetManualReOrder: ({ from, to }: StatsDateParams) => {
      const data = {
        date_from: from,
        date_to: to,
      };
      return this.request({
        url: '/admin/reorder_stuck_in_execution',
        method: 'POST',
        data,
      });
    },
  };
}
