



























































































































import Vue from 'vue';

import { RISK_EXAMPLES, EGRN_EXAMPLES, RIGHT_LIST_EXAMPLES } from 'shared/utils/constants/examples';
import startsWith from 'lodash/startsWith';

export default Vue.extend({
  name: 'AppProductInfoDialog',

  props: {
    product: Object as () => Product,
    onCheckout: Function,
  },

  computed: {
    isRiskProduct(): boolean {
      return this.product.product_name.indexOf('RiskAssessment') !== -1;
    },

    coupon(): Coupon | null {
      if (this.product.coupons) {
        return this.product.coupons[0];
      }

      return null;
    },

    time(): string {
      switch (this.product.product_name) {
        case 'EgrnObjectFast':
        case 'EgrnRightListFast':
          return '~ 30 - 50 мин.';
        case 'RiskAssessmentFast':
        case 'RiskAssessmentFastV2':
          return '~ 40 - 60 мин.';
        case 'RiskAssessment':
        case 'RiskAssessmentV2':
        case 'RiskAssessmentAdvanced':
          return '~12ч. (макс. 72ч.)';
        default: return '~12ч. (макс. 5 раб. дней)';
      }
    },

    descriptionByProduct(): string {
      switch (this.product.product_name) {
        case 'EgrnObjectFast':
        case 'EgrnObject':
          return 'Данный документ содержит в себе всю необходимую информацию о владельце недвижимости. В нем также указано наличие обременений на недвижимое имущество. Главная информация из отчетов ЕГРН:';
        case 'EgrnRightListFast':
        case 'EgrnRightList':
          return 'Данный отчёт содержит полную информацию о бывших владельцах, а также даты перехода прав и причины их смены.';
        case 'RiskAssessmentAdvanced':
        case 'RiskAssessmentFast':
        case 'RiskAssessment':
        case 'RiskAssessmentV2':
        case 'RiskAssessmentFastV2':
          return 'Данный отчет содержит полную информацию о недвижимости и собственниках:';
        default:
          return '';
      }
    },

    infoByProduct(): string[] {
      switch (this.product.product_name) {
        case 'EgrnObjectFast':
        case 'EgrnObject': {
          return [
            'Основные характеристики (адрес, площадь, тип, кадастр. стоимость...)',
            'ФИО настоящего владельца (если права зарегистрированы)',
            'Вид права на недвижимость, серию и номер свидетельства на собственность',
            'Наличие / отсутствие обременений',
            'Информация о правопритязаниях (при наличии прекращения)',
          ];
        }
        case 'EgrnRightListFast':
        case 'EgrnRightList': {
          return [
            'ФИО всех собственников с 1998 года',
            'Серию и номер свидетельства о регистрации',
            'Даты перехода прав',
            'Дополнительная информация',
            'Данные о каждом из Правообладателей.',
          ];
        }
        case 'RiskAssessmentFast':
        case 'RiskAssessmentV2':
        case 'RiskAssessmentFastV2':
        case 'RiskAssessmentAdvanced':
        case 'RiskAssessment': {
          return [
            'ФИО всех собственников с 1998 года',
            'Серию и номер свидетельства о регистрации',
            'Даты перехода прав на объект',
            'Информация о предыдущих собственниках',
            'Обременения (арест/залог/ипотека)',
            'Кадастровая стоимость объекта',
            'Задолженность собственников',
            'Проверка  действительности паспорта',
            'Проверка на банкротство собственника',
            'Наличие судебных разбирательств как по объекту, так и по собственнику',
            'Проверка объекта на наличие в списке на снос',
            'Проверка на массовую регистрацию по адресу',
            'Проверка на запрет регистрационных действий',
          ];
        }

        default: return [];
      }
    },

    productName() {
      return startsWith(this.product.product_name, 'RiskAssessment') ? 'Проверка недвижимости на риски' : this.product.product_name_ru;
    },
  },
  methods: {
    checkout() {
      this.$dialog.close();
      this.onCheckout(this.product);
    },

    showExamples(exampleType: string) {
      const examples: SimpleObject<any[]> = {
        risk: RISK_EXAMPLES,
        egrn: EGRN_EXAMPLES,
        rightList: RIGHT_LIST_EXAMPLES,
      };

      this.$photoSwipe(examples[exampleType]);
    },
  },
});
