type Statuses = 'loading' | 'loaded' | 'error';

const lodableItems: SimpleObject<Loadable> = {};

class Loadable {
  public resolvers: ((value?: any) => void)[] = [];
  private _status: Statuses = 'loading';

  get status(): Statuses {
    return this._status;
  }

  set status(status: Statuses) {
    this._status = status;

    if (status !== 'loading') {
      this.resolvers.forEach((resolver): void => resolver());
      this.resolvers = [];
    }
  }

  waitLoading(): Promise<any> {
    return new Promise((resolve): void => {
      if (this.status === 'loading') {
        this.resolvers.push(() => resolve(false));
      } else {
        resolve(false);
      }
    });
  }
}

// Возвращаем Promise<boolean>, чтобы узнать загрузился ли он в первый раз (true), или уже был загружен (false)
export default function loadExternalScript(scriptUrl: string, attributes?: SimpleObject): Promise<boolean> {
  let loadableItem = lodableItems[scriptUrl];

  if (loadableItem) {
    return loadableItem.waitLoading();
  } else {
    loadableItem = new Loadable();
    lodableItems[scriptUrl] = loadableItem;
  }

  return new Promise((resolve): void => {
    const script: any = document.createElement('script');

    if (attributes) {
      for (const key in attributes) {
        script[key] = attributes[key];
      }
    }

    script.onload = (): void => {
      loadableItem.status = 'loaded';
      resolve(true);
    };

    script.onload = (): void => {
      loadableItem.status = 'error';
      resolve(true);
    };

    script.async = true;
    script.src = scriptUrl;
    document.head.appendChild(script);
  });
}
