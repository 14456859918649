import Vue from 'vue';

export default function floatFilter(_Vue: typeof Vue) {
  _Vue.filter('float', (value: string) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      return value;
    }

    return parsedValue.toFixed(2);
  });
}
