





































































































































import Vue from 'vue';
import Axios from 'axios';
import _ from 'lodash';
import AppSuspiciousActivityDialog from 'shared/components/SuspiciousActivityDialog.vue';

// let cancel: Canceler;

const cache: { query: string; items: RealtyObject[] }[] = [];

export default Vue.extend({
  props: {
    query: {
      type: String,
      required: true,
    },

    loaderText: {
      type: String,
      default: 'Идет поиск объекта, это может занять некоторое время',
    },

    usePrevState: Boolean,

    data: Object,
  },

  data() {
    return {
      loading: true,
      error: false,
      object: null,
      searchResult: [],
    };
  },

  watch: {
    query(current, prev) {
      if (current !== prev) {
        this.searchObjects();
      }
    },
  },

  // lifecycle hooks
  created() {
    this.searchObjects();
  },

  beforeDestroy() {
    this.cancelRequest();
  },

  methods: {
    async searchObjects() {
      let objectInfo: any;

      try {
        let searchResult: any[];

        if (this.usePrevState) {
          const cachedResults = _.find(cache, { query: this.query });
          if (cachedResults && cachedResults.items.length > 1) {
            searchResult = cachedResults.items;
          }
        }

        if (!searchResult || (searchResult && searchResult.length === 0)) {
          searchResult = await this.fetchSearch();
          console.log('search result', searchResult);
          if (searchResult) {
            if (this.usePrevState) {
              cache.push({
                query: this.query,
                items: searchResult,
              });
            }

            this.searchResult = searchResult;

            if ((searchResult && searchResult.length <= 1) || !searchResult) {
              if (searchResult && searchResult.length === 1) {
                objectInfo = searchResult[0];
              } else {
                objectInfo = { Address: this.query };

                if (/^(?:[0-9]+[:|/|-]?){1,}$/.test(this.query)) {
                  objectInfo.Number = this.query;
                }
              }

              this.onSelect(objectInfo);
            }
          }
        }
      } catch (error) {
        console.error(error);
        if (Axios.isCancel(error)) {
          objectInfo = { Address: this.query };
          this.onSelect(objectInfo);
        }
      }

      this.loading = false;
    },

    async fetchSearch(): Promise<any> {
      this.loading = true;

      this.cancelRequest();

      try {
        const searchResult = await this.$api.object.Search(this.query, {
          cancelToken: new Axios.CancelToken((c) => ((this as any)._cancel = c)),
        });

        (this as any)._cancel = null;

        if (searchResult && searchResult.length > 1) {
          searchResult.forEach((item) => {
            item.loading = false;
            item.hideInfo = true;

            if ((item.Area as any) === 'не определена') {
              item.Area = 0;
            }

            if (item.AreaOKC === 'не определена') {
              item.AreaOKC = '';
            }
          });
        }

        return searchResult;
      } catch (error) {
        const err = this.$api.getErrorMessage(error);
        if (err === 'Suspicious activity detected' && !sessionStorage.getItem('suspActivityDetected')) {
          sessionStorage.setItem('suspActivityDetected', 'suspActivityDetected');
          const objectInfo: any = { Address: this.query };
          this.onSelect(objectInfo);
          this.$dialog.open({
            component: AppSuspiciousActivityDialog,
          });
        } else {
          if (Axios.isCancel(error)) {
            const objectInfo: any = { Address: this.query };
            this.onSelect(objectInfo);
          }
          this.$dialog.close();
        }
      }
    },

    async fetchObjectInfo(kadastrNumber: string) {
      const searchItem = _.find<RealtyObject>(this.searchResult, { Number: kadastrNumber });

      if (!searchItem || !searchItem.hideInfo) {
        return;
      }

      searchItem.loading = true;
      searchItem.hideInfo = false;

      try {
        const object = await this.$api.object.InfoFull(kadastrNumber);

        if (object.Area === 'не определена' && !_.isEmpty(object.AreaOKC)) {
          searchItem.Area = object.AreaOKC;
        } else {
          searchItem.Area = object.Area;
        }

        if (object.ObjectType) {
          if (object.ObjectType === 'Объект капитального строительства') {
            if (object.TypeOKC) {
              searchItem.ObjectType = object.TypeOKC;
            } else {
              searchItem.ObjectType = object.ObjectType;
            }
          } else {
            searchItem.ObjectType = object.ObjectType;
          }
        }
      } catch (error) {
        console.error(error);
      }

      searchItem.loading = false;
    },

    onSelect(objectInfo: RealtyObject) {
      this.$emit('select', objectInfo, this.searchResult && this.searchResult.length);
    },

    cancelRequest() {
      if ((this as any)._cancel) {
        (this as any)._cancel();
      }

      (this as any)._cancel = null;
    },
  },
});
