import { RealtyCloudApi } from '../api';
import { AxiosRequestConfig } from 'axios';

import { environment } from 'shared/environment';

export interface ApiAdvertMethods {
  List<T = AdvertsListResponse>(params: AdvertsListParams, config?: AxiosRequestConfig): Promise<T>;
  Info<T = RealtyAdvert>(id: number, config?: AxiosRequestConfig): Promise<T>;
  GetCadastralInfo<T = RealtyAdvert>(number: string, config?: AxiosRequestConfig): Promise<T>;
  GetReforma<T = RealtyAdvert>(address: string, config?: AxiosRequestConfig): Promise<T>;
  SimilarList<T = RealtyAdvert>(id: number, config?: AxiosRequestConfig): Promise<T>;
  GetAveragePrice<T = AveragePriceData>(id: number, config?: AxiosRequestConfig): Promise<T>;
  GetAveragePriceByParams<T = AveragePriceData>(params: AveragePriceByParamsData, config?: AxiosRequestConfig): Promise<T>;
  GetAdvertsByIds<T = AveragePriceData>(params: AdvertsGetByIdsParams, config?: AxiosRequestConfig): Promise<T>;
  GetAdvertsByAddress<T = number[]>(params: AdvertsListParams, config?: AxiosRequestConfig): Promise<T>;
  GetPriceChange<T = AveragePriceData>(params: PriceChangeParams, config?: AxiosRequestConfig): Promise<T>;
  GetPriceChangeByParams<T = AveragePriceData>(params: PriceChangeByParams, config?: AxiosRequestConfig): Promise<T>;
}

export function advertMethods(this: RealtyCloudApi): ApiAdvertMethods {
  return {

    List: (params: AdvertsListParams, config?: AxiosRequestConfig): any => {
      const data = params;

      return this.request({
        baseURL: environment.advertApiUrl,
        url: '/adverts/list',
        method: 'POST',
        data,
        ...config,
      }).then((data: AdvertsListResponse) => {
        return data;
      });
    },

    SimilarList: (id: number, config?: AxiosRequestConfig): any => {
      const params = { id };
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/adverts/getSimilarList',
        method: 'POST',
        params,
        ...config,
      }).then((data: any) => {
        return data;
      });
    },

    Info: (id: number, config?: AxiosRequestConfig): any => {
      const params = { id };
      return this.request({
        baseURL: environment.advertApiUrl,
        url: '/adverts/object',
        params,
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetCadastralInfo: (number: string, config?: AxiosRequestConfig): any => {
      const params = { kad_number: number };
      return this.request({
        baseURL: environment.advertApiUrl,
        url: '/adverts/object/kad',
        method: 'POST',
        data: params,
        ...config,
      }).then((data: CadastralInfoResponse) => {
        return data || [];
      });
    },

    GetReforma: (address: string, config?: AxiosRequestConfig): any => {
      const params = { address };
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/search/getRefByA',
        method: 'POST',
        params,
        ...config,
      }).then((data: ReformaResponse) => {
        return data;
      });
    },

    GetAveragePrice: (id, config?: AxiosRequestConfig): any => {
      const params = { id };
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/trend/getAverage',
        method: 'POST',
        params,
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetAveragePriceByParams: (params, config?: AxiosRequestConfig): any => {
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/trend/getAverageParam',
        method: 'POST',
        params,
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetPriceChange: (params, config?: AxiosRequestConfig): any => {
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/trend/getPriceChange',
        method: 'POST',
        params,
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetPriceChangeByParams: (params, config?: AxiosRequestConfig): any => {
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/trend/getPriceChangeParam',
        method: 'POST',
        params,
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetAdvertsByIds: ({ ids, fields }, config?: AxiosRequestConfig): any => {
      const data = { ids };
      const params = { fields };
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/dom/getByIDs',
        method: 'POST',
        data,
        params,
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetAdvertsByAddress: (params, config?: AxiosRequestConfig): any => {
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/search/getByAddress',
        method: 'POST',
        params,
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },
  };
}
