import EventEmitter from 'shared/utils/eventEmitter';

export const emitter = new EventEmitter();

export const EVENTS = {
  INIT: 'init',
  FORM_SUBMIT: 'form.submited',
  OBJECT_SELECTED: 'form.objectSelected',
  PAYMENT_SUCCESS: 'payment.success',
};
