


















































import Vue from 'vue';
import Axios, { Canceler, AxiosRequestConfig } from 'axios';
import throttle from 'lodash/throttle';
import noop from 'lodash/noop';
import VueHighlighter from 'vue-highlight-words';

interface DadataItem {
  value: string;
  unrestricted_value?: string;
  data?: any;
}

interface DadataResponse {
  suggestions: DadataItem[];
}

const DADATA_TOKEN = 'e2df26720b1aae53724486aff3a7cbde3cf711f8';
const DADATA_ENDPOINT = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';

let cancel: Canceler;

export default Vue.extend({
  name: 'AppDadata',

  components: { VueHighlighter },

  props: {
    suggestionDisabled: {
      type: Boolean,
      default: false,
    },
    minLength: {
      type: Number,
      default: 2,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Введите адрес или кадастровый номер',
    },
    size: String,
  },

  data() {
    return {
      query: this.value,
      savedQuery: '',
      visible: false,
      inputFocused: false,
      highlightIndex: -1,
      suggestions: [] as DadataItem[],
    };
  },

  computed: {
    nativeValueInput(): string {
      return this.value ? this.value : '';
    },

    isVisible(): boolean {
      return (
        !this.suggestionDisabled &&
        this.suggestions.length !== 0 &&
        this.visible &&
        this.inputFocused &&
        this.value.length > this.minLength
      );
    },

    highlightWords(): string[] {
      const excludeHighlightWards = [
        'г',
        'респ',
        'ул',
        'р-н',
        'пр-кт',
        'село',
        'деревня',
        'поселок',
        'пр-д',
        'пл',
        'к',
        'кв',
        'обл',
        'д',
      ];

      const words = this.savedQuery.replace(/,/g, '').split(' ');

      return words.filter((word) => {
        return excludeHighlightWards.indexOf(word) === -1;
      });
    },
  },

  methods: {
    animationEnter(el: HTMLDivElement) {
      el.style.height = `${el.scrollHeight}px`;
    },

    animationAfterEnter(el: HTMLDivElement) {
      el.style.height = '';
    },

    onSearch(query: string) {
      // const target = event.target as HTMLInputElement;
      // const query = target.value;

      /* this.query = */ this.savedQuery = query;
      this.highlightIndex = -1;
      this.visible = true;
      this.$emit('input', query);

      if (cancel) {
        cancel();
      }

      if (!this.suggestionDisabled) {
        this.requestSuggestionsData(query);
      }
    },

    requestSuggestionsData: throttle(function(this: any, query) {
      const config: AxiosRequestConfig = {
        params: {
          query,
          count: 8,
        },

        headers: {
          Authorization: `Token ${DADATA_TOKEN}`,
        },

        cancelToken: new Axios.CancelToken((c) => {
          cancel = c;
        }),
      };

      Axios.get<DadataResponse>(DADATA_ENDPOINT, config)
        .then((response) => response.data.suggestions)
        .then((data: DadataItem[]) => {
          this.suggestions = data;
        })
        .catch(noop);
    }, 200),

    onKeyUp(): void {
      if (!this.isVisible) {
        return;
      }

      let newIndex = this.highlightIndex - 1;
      let value: string;

      if (newIndex < -1) {
        newIndex = this.suggestions.length - 1;
      }

      if (newIndex === -1) {
        this.query = value = this.savedQuery;
      } else {
        this.query = value = this.suggestions[newIndex].value;
      }

      this.$emit('input', value);
      this.highlightIndex = newIndex;
    },

    onKeyDown(): void {
      if (!this.isVisible) {
        return;
      }

      let newIndex = this.highlightIndex + 1;
      let value: string;

      if (newIndex >= this.suggestions.length) {
        newIndex = -1;
        this.query = value = this.savedQuery;
      } else {
        this.query = value = this.suggestions[newIndex].value;
      }

      this.$emit('input', value);
      this.highlightIndex = newIndex;
    },

    onSelect(index: number) {
      let event = 'submit';
      let value = '';

      if (index >= 0 && index < this.suggestions.length) {
        event = 'select';
        value = this.query = this.savedQuery = this.suggestions[index].value + ' ';
        this.$emit('input', value);
      }

      this.$emit(event, value);
      this.visible = false;
      this.highlightIndex = -1;

      if (value) {
        this.moveCursorToEndLine(value.length);
      }
    },

    onFocus(): void {
      this.inputFocused = true;
      this.$emit('focus');
    },

    onBlur(): void {
      this.inputFocused = false;
    },

    preventCursorMove(event: KeyboardEvent): void {
      event.preventDefault();
      const target = event.target as HTMLInputElement;

      if (target.selectionStart !== undefined) {
        target.selectionEnd = target.selectionStart = target.value.length;
      }
    },

    preventSubmit(event: KeyboardEvent): void {
      event.preventDefault();
    },

    moveCursorToEndLine(endPosition: number) {
      requestAnimationFrame(() => {
        const inputComponent = this.$refs.input as Vue;
        const input = inputComponent.$el.querySelector('input');

        if (input) {
          input.focus();
          input.selectionStart = input.selectionEnd = endPosition;
        }
      });
    },
  },
});
