import Vue from 'vue';
import './registerComponents';
import './icons';
import App from './App.vue';
import { installApiPlugin } from 'shared/api';
import currencyFilter from 'shared/filters/currency.filter';
import floatFilter from 'shared/filters/float.filter';
import { emitter, EVENTS } from './emitter';
import initForm from '@/utils/initForm';
import { FormOptions, AppState } from './app-types';
import { Role } from 'shared/utils/constants';

Vue.config.productionTip = false;

Vue.use(installApiPlugin);
Vue.use(currencyFilter);
Vue.use(floatFilter);

export function init(el: HTMLDivElement, options: FormOptions) {
  if (!options.partnerId) {
    console.warn('[RealtycloudEGRNForm] Partner id не указан');
  }

  options = {
    title: 'Срочный заказ выписки ЕГРН',
    subtitle: 'Для получения выписки из ЕГРН введите кадастровый номер или адрес объекта',
    selectedProducts: ['EgrnObject'],
    recommendedProducts: ['RiskAssessment'],
    enableFastProducts: true,
    disableImage: false,
    allowFreeOrder: false,
    buttonText: 'Заказать выписку',
    orderTitle: 'Заказ выписки',
    ...options,
  };

  if (options.selectedProducts && options.enableFastProducts) {
    const fastProducts = options.selectedProducts.map((productName: any) => `${productName}Fast` as ProductName);
    options.selectedProducts = options.selectedProducts.concat(fastProducts);
  }

  const state: AppState = Vue.observable<AppState>({
    form: {
      query: '',
      email: '',
      phone: '',
      person_role: Role.Customer,
      target: 0,
    },

    auth: {
      user: null,
      jwt: null,
    },

    products: {
      items: [],
      byName: null,
      group: {},
    },

    targets: {
      0: 'Для каких целей',
      1: 'Купля-продажа недвижимости',
      2: 'Аренда недвижимости',
      3: 'Для суда',
      4: 'Для банка',
      5: 'Для налоговой',
    },

    objectInfo: null,

    order: null,

    orderItems: [],

    step: 'form',

    options,
  });

  const vm = new Vue({
    provide() {
      return { state };
    },
    render: h => h(App, {
      props: {
        discount: 0,
        code: null,
      },
    }),
    mounted() {
      emitter.emit(EVENTS.INIT);
    },
  }).$mount(el);

  return vm;
}

export const events = emitter;

export const EVENT_NAMES = EVENTS;

initForm<FormOptions>((el, options) => {
  if (options.preventAutoInit) {
    return;
  }

  init(el, options);
});
