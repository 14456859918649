import { RealtyCloudApi } from '../../api';

interface ConversionData {
  clientId?: string;
  items: OrderItem[];
  totalAmount: string;
  transactionId: string;
}

export interface ApiMeasurementMethods {
  Conversion(data: ConversionData): void;
}

function random(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function waitClientId() {
  return new Promise(resolve => {
    let tryCounts = 0;

    const intervalId = setInterval(() => {
      let clientId;

      try {
        const id = ga.getAll()[0].get('clientId');
        if (id) {
          clientId = id;
        }
      } catch (e) {
        // console.error('error: ', e);
      }

      if (clientId || tryCounts >= 15) {
        clearInterval(intervalId);
        return resolve(clientId);
      }

      tryCounts++;
    }, 1000);
  });
}

export function measurementMethods(this: RealtyCloudApi): ApiMeasurementMethods {
  return {
    Conversion: async (data: ConversionData) => {
      const clientId = await waitClientId();

      if (!clientId) {
        return Promise.resolve();
      }

      const url = 'https://www.google-analytics.com/collect';
      const defaultParams = {
        v: '1', // Версия аналитики
        tid: 'UA-122176305-1', // Tracking ID / Property ID.
        cid: clientId, // Anonymous Client ID.
        cu: 'RUB', // Currency Code
        ti: data.transactionId, // transaction ID. Required.
        ds: 'web',
      };

      const orderParams = {
        ...defaultParams,
        tr: data.totalAmount, // Transaction revenue. - общая сумма заказа
        t: 'transaction', // Transaction hit type. - тип хита
        ta: location.origin, // Transaction affiliation.
        z: random(1, 1e6),
      };

      await this._axios.get(url, { params: orderParams });

      data.items.forEach((item) => {
        const itemParams = {
          ...defaultParams,
          t: 'item', // Transaction hit type. - тип хита
          iq: '1', // Item Quantity Количество единиц товара
          in: item.product_name, // Item name. Required. - название товара
          ip: item.price.toString(), // Item price. - цена товара
          z: random(1, 1e6),
        };

        this._axios.get(url, { params: itemParams });
      });
    },
  };
}
