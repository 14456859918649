import { Api } from 'shared/api';
import { RealtyCloudApi } from 'shared/api/api';

export interface ApiTariffMethods {
  Items<T = Api.Tariff.Response.GetItems>(): Promise<T>;
  GetCurrent<T = Model.Tariff.Item>(): Promise<T>;
  GetCurrentForUser<T = Model.Tariff.Item>(params: Model.GetCurrentForUserData): Promise<T>;
  Activate<T = any>(id: number): Promise<T>;
  ActivateForUser<T = any>(data: Model.ActivateTariffForUserBody): Promise<T>;
}

export function tariffMethods(this: RealtyCloudApi): ApiTariffMethods {
  return {
    Items: () => {
      return this.request({ url: '/tariff/get_all' });
    },

    GetCurrent: () => {
      return this.request({ url: '/tariff/get_current' });
    },

    GetCurrentForUser: (params: Model.GetCurrentForUserData) => {
      return this.request({ url: '/tariff/get_current_for_user/', params });
    },

    Activate: (id: number) => {
      return this.request({
        url: '/tariff/activate',
        method: 'POST',
        data: { tariff_simple_id: id },
      });
    },

    ActivateForUser: (data) => {
      return this.request({
        url: '/tariff/activate_for_user',
        method: 'POST',
        data,
      });
    },
  };
}
