

























































































































import Vue from 'vue';

import { EXAMPLE_TYPES } from 'shared/utils/constants/examples';

export default Vue.extend({
  name: 'AppProductInfoDialog',

  props: {
    product: Object as () => Product,
    couponExpired: Boolean,
  },

  computed: {
    isRiskProduct(): boolean {
      return this.product.product_name.indexOf('RiskAssessment') !== -1;
    },

    time(): string {
      switch (this.product.product_name) {
        case 'RiskAssessment':
        case 'RiskAssessmentAdvanced':
          return 'от 12 ч.';
        case 'RiskAssessmentFast':
        case 'EgrnObjectFast':
        case 'EgrnRightListFast':
          return 'от 30 мин. (макс 1 ч.)';
        default: return 'от 2 ч.';
      }
    },

    descriptionByProduct(): string {
      switch (this.product.product_name) {
        case 'EgrnObjectFast':
        case 'EgrnObject':
          return 'Данный документ содержит в себе всю необходимую информацию о владельце недвижимости. В нем также указано наличие обременений на недвижимое имущество. Главная информация из выписки ЕГРН:';
        case 'EgrnRightListFast':
        case 'EgrnRightList':
          return 'Данная выписка содержит полную информацию о бывших владельцах, а также даты перехода прав и причины их смены.';
        case 'RiskAssessmentAdvanced':
        case 'RiskAssessmentFast':
        case 'RiskAssessment':
          return 'Данный отчет содержит полную информацию о недвижимости и собственниках:';
        default:
          return '';
      }
    },

    infoByProduct(): string[] {
      switch (this.product.product_name) {
        case 'EgrnObjectFast':
        case 'EgrnObject': {
          return [
            'Основные характеристики (адрес, площадь, тип, кадастр. стоимость...)',
            'ФИО настоящего владельца (если права зарегистрированы)',
            'Вид права на недвижимость, серию и номер свидетельства на собственность',
            'Наличие / отсутствие обременений',
            'Информация о правопритязаниях (при наличии)',
          ];
        }
        case 'EgrnRightListFast':
        case 'EgrnRightList': {
          return [
            'ФИО всех собственников с 1998 года',
            'Серию и номер свидетельства о регистрации',
            'Даты перехода прав',
            'Дополнительная информация',
            'Данные о каждом из Правообладателей.',
          ];
        }
        case 'RiskAssessmentAdvanced':
        case 'RiskAssessmentFast':
        case 'RiskAssessment': {
          return [
            'ФИО всех собственников с 1998 года',
            'Серию и номер свидетельства о регистрации',
            'Даты перехода прав',
            'Обременения (арест/залог)',
            'Кадастровую стоимость',
            'Задолженность собственников',
            'Проверка действительности паспорта',
            'Банкротство',
            'Судебные разбирательства',
            'В списке на снос',
            'Массовая регистрация',
          ];
        }

        default: return [];
      }
    },
  },
  methods: {
    showExamples(exampleType: string) {
      this.$photoSwipe(EXAMPLE_TYPES[exampleType]);
    },
  },
});
