import uniq from 'lodash/uniq';

export default class LocStorage {
  static getItem<T = any>(key: string, defaultValue?: any): T {
    if (localStorage[key]) {
      try {
        const res = JSON.parse(localStorage[key]);
        return res || defaultValue;
      } catch (error) {
        console.error(error);
        return localStorage[key] || defaultValue;
      }
    }

    return defaultValue;
  }

  static setItem(key: string, data: any) {
    if (typeof data === 'object') {
      try {
        localStorage[key] = JSON.stringify(data);
      } catch (error) {
        console.error(error);
        localStorage[key] = data;
      }
    } else {
      localStorage[key] = data;
    }
  }

  static hasItem(key: string): boolean {
    return key in localStorage;
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  static updateItem(key: string, data: any) {
    const isArray = Array.isArray(data);
    const oldValue = LocStorage.getItem(key, isArray ? [] : {});

    if (typeof data === 'object' && !isArray) {
      data = { ...oldValue, ...data };
    }

    if (isArray) {
      data = oldValue.concat(data);
    }

    LocStorage.setItem(key, data);
  }

  static push(key: string, pushItem: any): number {
    const array = LocStorage.getItem(key, []);

    const index = array.push(pushItem);

    LocStorage.setItem(key, uniq(array));

    return index;
  }
}
