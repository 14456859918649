export const SEPARATORS = '(?::|;|\\^|\\||\\.|-)';

/** Регулярное выражения кадастрового номера с неправильными разделителям, такие как "; ^ |" */
export const CADASTRAL_NUMBER_WITH_WRONG_SEPARATORS_REGEX = new RegExp(`(\\d{2})${SEPARATORS}+(\\d{2})${SEPARATORS}+(\\d{6,7})${SEPARATORS}+(\\d{1,})`);

/** Проверяет похоже ли значение на кадастровый номер
 *
 * Сравнение идёт и с неправильными кадастровыми разделителями такими как "; ^ |"
 *
 * Также если переданы только числа
 *
 * @example
 * likeCadastralNumber('50 ; 10 ; 0010317 ; 35') => true;
 *
 * likeCadastralNumber('47^16^0101006^2126') => true;
 *
 * likeCadastralNumber('90220103152096') => true;
 *
 * likeCadastralNumber('1234') => false;
 *
 * likeCadastralNumber('Москва, проспект ленина 12') => false;
 */
export const likeCadastralNumber = (value: string | number): boolean => {
  if (typeof value === 'number') {
    value = value.toString();
  }

  // Убираем все пробелы
  value = value.replace(/\s/g, '');

  // Проверяем, состоит ли строка только из чисел
  if (/^\d+$/.test(value)) {
    // Этот способ проверки весьма не надёжен, но какой есть
    // Минимальная длина кадастрового номера это 11, а может и 12 🤔, кто его знает
    // Максимальная 16, но это не точно 😆
    return value.length >= 11 && value.length <= 16;
  }

  return CADASTRAL_NUMBER_WITH_WRONG_SEPARATORS_REGEX.test(value);
};

/** Регулярное выражение обычного кадастрового номера */
export const CDATASTRAL_NUMBER_REGEX = /\d{2}:\d{2}:\d{6,7}:\d{1,}/;

/** Проверяет является ли строка обычным кадастровым номером
 *
 * @example
 * isCadastralNumber('77:04:0002010:1101') => true;
 */
export const isCadastralNumber = (value: string): boolean => {
  return CDATASTRAL_NUMBER_REGEX.test(value);
};
