var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g(_vm._b({class:[
    _vm.$style.btn,
    _vm.size && _vm.$style[("btn-" + _vm.size)],
    _vm.block && _vm.$style['btn-block'],
    _vm.loading && _vm.$style['btn-loading'],
    _vm.rounded && _vm.$style.rounded,
    _vm.active && 'active',
    _vm.$style[("btn-" + _vm.variant)] ],attrs:{"disabled":_vm.disabled || _vm.loading,"type":_vm.type}},'button',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }