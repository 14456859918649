import { RealtyCloudApi } from 'shared/api/api';

export interface ApiRiskMethods {
  AddOwner<T = any>(data: OwnerItemRequestBody[]): Promise<T>;
  UpdateSurvey<T = any>(data: SurveyRequestBody): Promise<T>;
  AddOwnerV2<T = any>(data: LawInformationOwner[]): Promise<T>;
  UpdateOwner<T = any>(data: OwnerRequestBody): Promise<T>;
  UpdateOwnerV2<T = any>(data: LawInformationOwner): Promise<T>;
  RemoveOwnerV2<T = any>(data: LawInformationOwnerRemoveParams): Promise<T>;
  RestartTaskV2<T = any>(data: LawInformationOwnerRestartTaskParams): Promise<T>;
  UpdateReportV2<T = any>(data: UpdateRiskReportV2RequestBody): Promise<T>;
  GetReport<T = GetRiskReportResponse>(orderItemId: OrderItemID): Promise<T>;
  GetReportStatementsData<T = RiskReportStatementsResponse>(orderItemId: OrderItemID): Promise<T>;
  CreateReport<T = RiskStatusResponse>(data: RiskCreateRequestBody): Promise<T>;
  CreatePDF<T = RiskStatusResponse>(orderItemId: string): Promise<T>;
  UpdateOwnerItem<T = RiskStatusResponse>(data: RiskUpdateOwnerItemRequestBody): Promise<T>;
  RemoveOwnerItem<T = RiskStatusResponse>(data: RiskRemoveOwnerItemRequestBody): Promise<T>;
  UpdateScraperStatus<T = RiskStatusResponse>(data: UpdateScraperStatusRequestBody): Promise<T>;
  AddConclusion<T = any>(data: AddConclusionRequest): Promise<T>;
  UpdateReport<T = any>(data: UpdateRiskReportRequestBody): Promise<T>;
  GetRiskReportV2<T = LawInformation>(id: string): Promise<T>;
}

export function riskMethods(this: RealtyCloudApi): ApiRiskMethods {
  return {
    AddOwner: (data: OwnerItemRequestBody[]) => {
      return this.request({
        url: '/risk/owner',
        method: 'POST',
        data,
      });
    },

    AddOwnerV2: (data: LawInformationOwner[]) => {
      return this.request({
        url: '/risk/v2/owner',
        method: 'POST',
        data,
      });
    },

    UpdateOwner: (data: OwnerRequestBody) => {
      return this.request({
        url: '/risk/owner',
        method: 'PUT',
        data,
      });
    },

    UpdateOwnerV2: (data: LawInformationOwner) => {
      return this.request({
        url: '/risk/v2/owner',
        method: 'PUT',
        data,
      });
    },

    RemoveOwnerV2: (data: LawInformationOwnerRemoveParams) => {
      return this.request({
        url: '/risk/v2/owner',
        method: 'DELETE',
        data,
      });
    },

    RestartTaskV2: (data: LawInformationOwnerRestartTaskParams) => {
      return this.request({
        url: '/risk/v2/task/restart',
        method: 'POST',
        data,
      });
    },

    GetReport: (orderItemId: OrderItemID) => {
      return this.request({
        url: `/admin/risk/report/${orderItemId}`,
      });
    },

    GetReportStatementsData: (orderItemID: OrderItemID) => {
      return this.request({ url: `/admin/risk/report/${orderItemID}/statements` });
    },

    CreateReport: (data: RiskCreateRequestBody) => {
      return this.request({
        url: '/admin/risk/report',
        method: 'POST',
        data,
      });
    },

    CreatePDF: (orderItemID: OrderItemID) => {
      return this.request({
        url: '/admin/risk/report/create_pdf',
        method: 'POST',
        params: { orderItemID },
      });
    },

    UpdateOwnerItem: (data: RiskUpdateOwnerItemRequestBody) => {
      return this.request({
        url: '/admin/risk/report/owner_item',
        method: 'PUT',
        data,
      });
    },

    // ResetReportItem: (data: ResetReportItemBody) => {
    //   return this.request({
    //     url: '/admin/risk',
    //     method: 'PUT',
    //     data,
    //   });
    // },

    RemoveOwnerItem: (data: RiskRemoveOwnerItemRequestBody) => {
      return this.request({
        url: '/admin/risk/report/owner_item',
        method: 'DELETE',
        data,
      });
    },

    AddConclusion: (data: AddConclusionRequest) => {
      return this.request({
        url: '/admin/risk/report/conclusion',
        method: 'PUT',
        data,
      });
    },

    UpdateScraperStatus: (data: UpdateScraperStatusRequestBody) => {
      return this.request({
        url: '/admin/risk/report/owner_item/status',
        method: 'PUT',
        data,
      });
    },

    UpdateReport: (data: UpdateRiskReportRequestBody) => {
      return this.request({
        url: '/admin/risk/report/update_statement',
        method: 'PUT',
        data,
      });
    },

    GetRiskReportV2: (orderItemId: OrderItemID): any => {
      return this.request({
        url: `/risk/v2/report/${orderItemId}`,
      }).then((data: any) => {
        return data[0] || null;
      });
    },

    UpdateSurvey: (data: SurveyRequestBody) => {
      return this.request({
        url: '/risk/v2/survey',
        method: 'POST',
        data,
      });
    },

    UpdateReportV2: (data: UpdateRiskReportV2RequestBody) => {
      return this.request({
        url: '/risk/v2/update_statement',
        method: 'POST',
        data,
      });
    },
  };
}
