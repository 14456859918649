import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faShieldAlt, faHourglassStart, faPaste, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';

library.add(
  faShieldAlt,
  faCreditCard,
  faHourglassStart,
  faPaste,
  faCheckCircle,
);

Vue.component('app-icon', FontAwesomeIcon);
