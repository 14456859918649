













import Vue from 'vue';
import Component from 'vue-class-component';
import { Provide } from 'vue-property-decorator';
import noop from 'lodash/noop';

import ModelValidator from 'shared/utils/validator/modelValidator';
import isEmpty from 'lodash/isEmpty';

@Component({
  name: 'AppForm',

  props: {
    rules: {
      type: Function,
      default: noop,
    },
    model: {
      type: [ Object, Array ],
      required: true,
    },
    requestHandler: Function,
  },
})
export default class Form extends Vue {
  // props
  rules!: (model?: any) => ValidationConfig | undefined;
  requestHandler!: () => Promise<any>;
  model!: SimpleObject<any> | any[];
  // props end

  // data()
  loading: boolean = false;
  @Provide()
  Form: FormProvider = {
    errors: {},
  };

  async onSubmit(event: Event) {
    if (this.loading) {
      return;
    }

    let errors;

    if (Array.isArray(this.model)) {
      errors = {};
      this.model.forEach((model: any, index) => {
        const validationErrors = this.validate(model);

        if (validationErrors) {
          errors[index] = validationErrors;
        }
      });
    } else {
      errors = this.validate(this.model);
    }

    if (!isEmpty(errors)) {
      this.$emit('validation-error', errors);
      return (this.Form.errors = errors);
    }

    this.Form.errors = {};

    this.$emit('submit', event);

    if (this.requestHandler) {
      this.loading = true;

      await this.requestHandler();

      this.loading = false;
    }
  }

  validate(model: any): ValidationErrors {
    const validator: ModelValidator = new ModelValidator(this.rules(model));
    return validator.validate(model);
  }
}
