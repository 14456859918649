






































































































// import { Notyf } from 'notyf';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import { Component, Vue, Inject } from 'vue-property-decorator';

import { AppState } from './app-types';

import { Validators } from 'shared/utils/validator';
import LocStorage from 'shared/utils/localStorage';

import AppActionsDialog from './ActionsDialog.vue';

import { emitter, EVENTS } from './emitter';
import { isCadastralNumber } from 'shared/utils/cadastralNumber';
import { Role, RoleNames } from 'shared/utils/constants';

interface Auth {
  user: User;
  token: string;
}
declare var cp: any;

// const notyf = new Notyf({ duration: 7000 });

@Component({
  props: {
    discount: Number,
    code: String,
  },
})
export default class App extends Vue {
  // props
  discount!: number;
  code!: string;

  // inject
  @Inject() state: AppState;

  // data
  // loading: boolean = false;
  roles: typeof RoleNames = {
    ...omit(RoleNames, Role.Admin, Role.Partner),
    [Role.Agent]: 'Агенство недвижимости',
    [Role.Client]: 'Другое',
  };

  get loading(): boolean {
    return this.state.step !== 'form';
  }

  // lifecycle hooks
  async created() {
    const auth: User | null = LocStorage.getItem('user');

    if (auth) {
      this.state.form.email = auth.email;
    }
  }

  // methods
  validationRules(): ValidationConfig {
    const config: ValidationConfig = {
      query: [ Validators.required ],
      email: [ Validators.required, Validators.email ],
    };

    // if (this.state.options.allowFreeOrder) {
    //   config.target = [ Validators.notEq(0, 'Обязательное поле') ];

    //   if (this.state.form.target === 1) {
    //     config.phone = [ Validators.required, Validators.phone ];
    //     config.person_role = [ Validators.required ];
    //   }
    // }

    return config;
  }

  async getProducts() {
    let products: Product[] = [];
    const params: any = {
      partnerID: this.state.options.partnerId,
    };

    // Передаём кадастровый номер, для формирования цены под регион
    if (isCadastralNumber(this.state.form.query)) {
      params.kadastrNumber = this.state.form.query;
    }

    // Передаём roleId, для формирования цен
    if (this.state.form.person_role) {
      params.personRoleID = this.state.form.person_role;
    }

    try {
      products = await this.$api.products.List(null, params);

      products.forEach(product => {
        if (product.product_name === 'EgrnObject') {
          product.product_description = 'Содержит информацию о текущем владельце, наличии обременений (запретов/арестов/ипотеки), кадастровой стоимости...';
        }

        if (product.product_name === 'RiskAssessment') {
          product.product_description = 'Полная проверка объекта недвижимости <br> Проверка собственников в базах МВД, ФНС, ФССП и др. <br> Содержит обе выписки из ЕГРН с СЦП';
        }

        if (product.product_name === 'EgrnRightList') {
          product.product_description = 'История купли-продажи объекта, информация обо всех предыдущих владельцах...';

          // Если установлено, что можно заказывать бесплатно
          // То добавляем этот бесплатный купон для Выписок о переходе прав
          if (this.state.options.allowFreeOrder) {
            const freeCoupon: Coupon = {
              discount: product.product_price,
              description: '100%',
              name: 'skidka100pravo',
            };

            product.coupons = [ freeCoupon ];
          }
        }
      });
    } catch (error) {
      console.error(error);
    }

    const groupedProducts: OfficialProductMap = products.reduce((group: OfficialProductMap, product: Product) => {
      let productName = product.product_name;
      let target: 'default' | 'fast' = 'default';
      if (/fast/i.test(productName)) {
        productName = productName.replace(/fast/i, '') as ProductName;
        target = 'fast';
      }

      if (!(productName in group)) {
        group[productName] = {
          default: null,
          fast: null,
        };
      }

      group[productName][target] = product;

      return group;
    }, {});

    this.state.products.items = products;
    this.state.products.group = groupedProducts;
    this.state.products.byName = keyBy(products, 'product_id');
  }

  async onSubmit() {
    // this.loading = true;

    emitter.emit(EVENTS.FORM_SUBMIT);
    this.state.step = 'search';

    await this.getProducts();

    this.$dialog.open({
      component: AppActionsDialog,
      props: {
        onClose: () => {
          // if (!this.object) {
          this.$emit('form.searchCanceled');
          // this.loading = false;
          // }
        },
      },
    });
  }
}
