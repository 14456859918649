import Vue from 'vue';
import tippy, { Props } from 'tippy.js';

// let tippyModuleLoading = false;
// let tippy: Tippy;

export default function installTippyPlugin<T = Props>(_Vue: typeof Vue, defaultOptions: T) {
  // const lazyInitElements: any[] = [];

  function createTippy({ el, options }: { el: any; options: any }) {
    // if (tippyModuleLoading) {
    //   return lazyInitElements.push({ el, options });
    // }

    if (!el) {
      return;
    }

    let content;
    const contentTemplate: HTMLElement = el.querySelector('[data-tippy-content]');

    if (options) {
      content = options.content;
    }

    if (contentTemplate) {
      content = contentTemplate;
      contentTemplate.setAttribute('data-tippy-content', '');
    }

    if (!options && !content) {
      return;
    }

    tippy(el, { ...defaultOptions, ...options, content });
  }

  // async function loadTippy() {
  //   if (!tippy && !tippyModuleLoading) {
  //     tippyModuleLoading = true;

  //     try {
  //       const tippyModule = await import('tippy.js');

  //       tippyModuleLoading = false;
  //       tippy = tippyModule.default;
  //       lazyInitElements.forEach(createTippy);
  //       lazyInitElements.length = 0;
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // }

  _Vue.directive('tippy', {
    inserted(el, binding) {
      // if (!tippy && !tippyModuleLoading) {
      //   loadTippy();
      // }

      if (binding.modifiers.input) {
        el = el.querySelector('.form-control');
      }

      createTippy({ el, options: binding.value });
    },

    unbind(el: any, binding) {
      if (binding.modifiers.input) {
        el = el.querySelector('.form-control');
      }

      el && el._tippy && el._tippy.destroy();
    },

    componentUpdated(el: any, binding) {
      if (!binding.value) {
        return;
      }
      const opts = binding.value || {};
      const oldOpts = binding.oldValue || {};
      const show = binding.arg === 'show';

      if (binding.modifiers.input) {
        el = el.querySelector('.form-control');
      }

      if (el) {
        if (el._tippy && JSON.stringify(opts) !== JSON.stringify(oldOpts)) {
          el._tippy.set(opts);
        }

        if (el._tippy && show && opts.trigger === 'manual') {
          el._tippy.show();
        } else if (el._tippy && !show && opts.trigger === 'manual') {
          el._tippy.hide();
        }
      }
    },
  });
}
