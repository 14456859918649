



















import Vue from 'vue';

export default Vue.extend({
  name: 'AppButton',

  props: {
    type: {
      type: String,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    size: String,
    block: Boolean,
    loading: Boolean,
    disabled: Boolean,
    active: Boolean,
    rounded: Boolean,
  },
});
