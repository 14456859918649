






























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppSuspiciousActivityDialog',
  popupOptions: {
    name: 'popup_default popup_suspicious-activity',
    size: 'sm',
  },
})

export default class AppSuspiciousActivityDialog extends Vue {
  // data
  email = 'help@realtycloud.ru';

  // methods
  hideDialog() {
    this.$dialog.close();
  }
}
