








































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import Axios, { Canceler } from 'axios';
import pick from 'lodash/pick';
import first from 'lodash/first';
import keys from 'lodash/keys';
import mergeWith from 'lodash/mergeWith';
// import isNil from 'lodash/isNil';

import AppProductWrapper from '@/components/ProductWrapper.vue';
import AppProductInfoDialog from './ProductInfo.vue';
import AppObjectDetailFooter from './ObjectDetailFooter.vue';
// import AppReCaptcha from './components/ReCaptcha.vue';
// import AppProductItem from './components/ProductItem.vue';
import AppProductItem from 'shared/components/Product/Item/ProductItem.vue';

import checkCadastralRegions from 'shared/utils/cadastralNumber/checkCadastralRegions';
import loadExternalScript from 'shared/utils/loadExternalScript';
import LocStorage from 'shared/utils/localStorage';
import { environment } from 'shared/environment';

import { AppState } from './app-types';
import { EVENTS, emitter } from './emitter';
declare var cp: any;

let cancel: Canceler | null;

@Component({
  name: 'AppPurchase',
  components: { AppProductWrapper, AppObjectDetailFooter, AppProductItem },
})
export default class Purchase extends Vue {
  @Inject() state: AppState;

  // data
  tooltipContent: string = 'Выписка формируется в течение дня, но иногда нужно подождать до трёх дней.';
  loading: boolean = false;
  // captcha: any = {
  //   id: null,
  //   showValditaionMessage: false,
  // };
  showCouponExpiredMessage: boolean = false;
  selected: UUID[] = [];

  // lifecycle
  async created() {
    if (this.state.options.defaultCheckedProducts) {
      this.state.options.defaultCheckedProducts.forEach((productName: ProductName) => {
        if (this.productsGroup[productName]) {
          this.selected.push(this.productsGroup[productName].default.product_id);
        }
      });
    }

    console.log(this.selected, this.productsGroup, this.state);
    if (this.selected.length === 0) {
      const firstKey = first(keys(this.productsGroup));
      this.selected.push(this.productsGroup[firstKey].default.product_id);
    }

    try {
      if (this.state.objectInfo.Number) {
        const data = await this.$api.object.InfoFull(this.state.objectInfo.Number, {
          cancelToken: new Axios.CancelToken((c) => (cancel = c)),
        });

        const info = mergeWith({}, this.state.objectInfo, data, (infoValue, fullInfoValue, key) => {
          if (key === 'UpdatedAt') {
            return fullInfoValue;
          }

          if (infoValue) {
            return infoValue;
          }

          // eslint-disable-next-line
          if (fullInfoValue && fullInfoValue != infoValue) {
            return fullInfoValue;
          }
        });

        this.state.objectInfo = info;
      }
    } catch (error) {
      console.error(error);
    }
  }

  beforeDestroy() {
    if (cancel) {
      cancel();
    }
  }

  get allowedFastOrder(): boolean {
    return this.state.options.enableFastProducts && checkCadastralRegions(this.state.objectInfo.Number);
  }

  /** Из списка продуктов выбираем только которые указаны в options.selectedProducts */
  get productsGroup(): OfficialProductMap {
    return pick(this.state.products.group, this.state.options.selectedProducts);
  }

  /** Общая сумма заказа */
  get totalAmount(): number {
    let amount = 0;

    for (const productId of this.selected) {
      const product = this.state.products.byName[productId];
      let productPrice = parseInt(product.product_price, 10);

      if (product.coupons) {
        const [ coupon ] = product.coupons;
        productPrice -= parseInt(coupon.discount, 10);
      }

      amount += productPrice;
    }

    return amount;
  }

  // methods
  async regorder() {
    let couponUsed = false;
    const body: RegorderRequestPayload = {
      order_items: [],
      email: this.state.form.email,
      registartion_url: location.href,
    };

    const objectKey = this.state.objectInfo.Number || this.state.objectInfo.Address || this.state.form.query;

    if (this.state.options.partnerId) {
      body.partner_id = this.state.options.partnerId;
    }

    for (const productId of this.selected) {
      const product: Product = this.state.products.byName[productId];
      const orderItem: OrderItem = {
        product_id: productId,
        object_key: objectKey,
      };

      /** Проверка для бесплатного заказа
       * Если есть купоны и в опциях позволено заказывать бесплатно
       * То в тело добавляем этот купон
       */
      if (
        this.state.options.allowFreeOrder &&
        product.product_name === 'EgrnRightList' &&
        Array.isArray(product.coupons)
      ) {
        orderItem.code = 'skidka100pravo';
        couponUsed = true;
        delete body.partner_id;
      }

      body.order_items.push(orderItem);
    }

    // if (this.state.options.allowFreeOrder && couponUsed) {
    //   this.captcha.showValditaionMessage = false;

    //   if (isNil(this.captcha.id)) {
    //     this.captcha.showValditaionMessage = true;
    //     return;
    //   }

    //   body.recaptcha_response = this.captcha.id;
    // }

    // if (this.state.form.target === 1) {
    //   body.person_role = this.state.form.person_role;
    //   body.phone = this.state.form.phone;
    //   body.comment = this.state.targets[this.state.form.target];
    // }

    this.loading = true;
    this.showCouponExpiredMessage = false;

    try {
      const { data, jwt } = await this.$api.raw.order.RegOrder<ApiRawResponse<RegOrderResponse & JWTResponse>>(body);

      const totalAmount = parseInt(data.order.total_amount, 10);

      /**
       * Проверяем по цене, был ли применён бесплатный купон
       * Если цена на EgrnRightList больше 0, то значит, что пользователь уже использовал купон раньше,
       * и надо выдать предупреждение, чтобы он его оплатил
       */
      for (const orderItem of data.order.order_items) {
        if (orderItem.product_name === 'EgrnRightList' && couponUsed) {
          const price = parseInt(orderItem.price, 10);
          if (price > 0) {
            this.state.products.group.EgrnRightList.default.coupons = null;
            this.showCouponExpiredMessage = true;
          }
        }
      }

      this.state.order = data.order;
      this.state.person = data.person; // добавил
      if (jwt) { // добавил
        this.state.auth = {
          user: jwt.person,
          jwt,
        };
      } // добавил

      if (!this.showCouponExpiredMessage) {
        if (totalAmount > 0) {
          this.openPaymentDialog();
        } else {
          this.state.step = 'success';
        }
      }
      if (jwt) { // добавил
        LocStorage.setItem('user', jwt.person);
      } // добавил
    } catch (error) {
      console.error(error);
    }

    this.loading = false;
  }

  async openPaymentDialog() {
    await loadExternalScript('https://widget.cloudpayments.ru/bundles/cloudpayments');

    const widget = new cp.CloudPayments();

    const order = this.state.order;
    const user = this.state.person; // добавил

    widget.charge({
      // options
      publicId: environment.cloudpaymentsApiKey, // id из личного кабинета
      description: 'Заказ выписок', // назначение
      amount: parseInt(order.total_amount as any, 10), // сумма
      currency: 'RUB', // валюта
      invoiceId: order.id, // номер заказа  (необязательно)
      accountId: user.email, // идентификатор плательщика (необязательно) // добавил
      skin: 'classic', // дизайн виджета
      data: {
        userId: user.id, // добавил
      },
    },
    () => {
      this.state.orderItems = this.state.orderItems.concat(order.order_items);
      this.state.step = 'success';
      emitter.emit(EVENTS.PAYMENT_SUCCESS, { order_price: order.total_amount, currency: 'RUB' });
    },
    function() {
      console.log('payment error');
      widget.hideWindow();
    });
  }

  changeSelectedProducts(selected: UUID[]) {
    this.selected = selected;
  }

  showInfo(product: Product) {
    this.$dialog.open({
      component: AppProductInfoDialog,
      props: { product},
    });
  }
}
