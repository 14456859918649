import { RealtyCloudApi } from '../api';
import { AxiosRequestConfig } from 'axios';

export interface ApiFilesMethods {
  GetFileBlob<T>(downloadUrl: string, params?: AxiosRequestConfig['params']): Promise<T>;
}

export function filesMethods(this: RealtyCloudApi): ApiFilesMethods {
  return {
    GetFileBlob: (url: string, params?: AxiosRequestConfig['params']) => {
      return this.request({ url, responseType: 'blob', params });
    },
  };
}
