

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';

@Component({
  name: 'AppFormGroup',
  props: {
    label: String,
    modelName: String,
    modelId: [ Number, String ],
  },
})
export default class FormGroup extends Vue {
  // props
  modelName!: string;
  modelId!: number | string;

  @Inject() Form!: FormProvider;

  get errorMessage(): string | null {
    if (this.Form && this.Form.errors) {
      if (this.modelId != null) {
        const errors = (this.Form.errors as SimpleObject<ValidationErrors>)[this.modelId];
        return errors && errors[this.modelName];
      } else {
        return (this.Form.errors as ValidationErrors)[this.modelName];
      }
    }

    return null;
  }
}
