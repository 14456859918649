import { RealtyCloudApi } from '../api';
import { AxiosRequestConfig } from 'axios';

import { environment } from 'shared/environment';

interface CreateDealParams {
  initiator_role: string;
  target_date?: Date;
  payment_type?: string;
  meeting_type?: string;
}

interface AddDocumentParams {
  deal_id: string;
  document: {
    id: string;
    type: string;
    name: string;
  };
}

export interface ApiDealMethods {
  CreateDeal<T = RealtyAdvert[]>(params: CreateDealParams, config?: AxiosRequestConfig): Promise<T>;
  AddDocument<T = RealtyAdvert[]>(params: AddDocumentParams, config?: AxiosRequestConfig): Promise<T>;
}

export function dealMethods(this: RealtyCloudApi): ApiDealMethods {
  return {

    CreateDeal: (params: CreateDealParams, config?: AxiosRequestConfig): any => {
      const data = params;

      return this.request({
        baseURL: environment.apiUrl,
        url: '/deal/create',
        method: 'POST',
        data,
        ...config,
      }).then((data: CreateDealResponse) => {
        return data;
      });
    },

    AddDocument: (params: AddDocumentParams, config?: AxiosRequestConfig): any => {
      const data = params;

      return this.request({
        baseURL: environment.apiUrl,
        url: '/deal/add_document',
        method: 'POST',
        data,
        ...config,
      }).then((data: RealtyAdvert[]) => {
        return data;
      });
    },
  };
}
